import { DateTime } from 'luxon'

export function validateTemplate(parsedSheet, requiredHeaders) {
  const errors = {
    invalidNumberColumns: [],
    notFoundHeaders: [],
    invalidHeaders: []
  }

  const headers = parsedSheet.meta.fields
  if (headers.length < requiredHeaders.length || headers.length > requiredHeaders.length) {
    errors.invalidNumberColumns.push({
      line: 1,
      error: 'Quantidade incorreta de colunas ',
      details: `Existem ${headers.length} colunas no arquivo importado, mas ${requiredHeaders.length} colunas são necessárias.`
    })
  }

  const requiredHeadersName = requiredHeaders.map(field => field.name)

  requiredHeadersName.forEach(fieldName => {
    if (!headers.includes(fieldName)) {
      errors.notFoundHeaders.push({
        line: 1,
        error: `Cabeçalho ${fieldName} não encontrado`
      })
    }
  })

  headers.forEach(header => {
    if (!requiredHeadersName.includes(header)) {
      errors.invalidHeaders.push({
        line: 1,
        error: `Cabeçalho ${header} não é compatível com tipo de oferta selecionado`
      })
    }
  })

  return errors
}

export function convertAndValidateCampaigns(parsed, templateFields) {
  const errors = {
    invalidTypes: [],
    missingInfo: []
  }
  const converted = []

  parsed.data.forEach((campaign, index) => {
    const keys = Object.keys(campaign)
    for (const key of keys) {
      const currentValidatingField = templateFields.find(field => field.name === key)
      if (!currentValidatingField) continue
      const value = campaign[key]
      if (!currentValidatingField.required && !value) continue
      try {
        campaign[key] = fieldTypeValidation(value, currentValidatingField)
      } catch (error) {
        errors.invalidTypes.push({
          line: index + 2,
          error: `Valor "${value}" inválido para o campo ${key}. ${error.message}`
        })
      }
    }
    converted.push(campaign)
  })

  return { errors, converted }
}

function fieldTypeValidation(value, currentValidatingField) {
  const acceptedBooleanValues = ['sim', 'não', 'nao', 's', 'n']
  try {
    let result
    switch (currentValidatingField.type) {
      case 'float':
        result = parseFloat(value)
        if (Number.isNaN(result)) {
          throw new Error(
            `Esperado "${typeTranslation(currentValidatingField.type)}", 
            mas foi encontrado "${typeTranslation(typeof value)}". Formato aceito: números decimais.`
          )
        }
        return result
      case 'int':
        result = parseFloat(value)
        if (Number.isNaN(result)) {
          throw new Error(
            `Esperado "${typeTranslation(currentValidatingField.type)}", 
            mas foi encontrado "${typeTranslation(typeof value)}". Formato aceito: números inteiros.`
          )
        }
        if (!Number.isInteger(result)) {
          throw new Error(
            `Esperado "${typeTranslation(currentValidatingField.type)}", 
            mas foi encontrado "${typeTranslation(typeof value)}". Formato aceito: números inteiros.`
          )
        }
        return parseInt(result)
      case 'string':
        value = value?.toString()
        value = value?.trim()
        value = value?.replace('\\r', '')
        value = value?.replace('\\t', '')
        if (typeof value !== 'string' || value.length <= 0) {
          throw new Error(
            `Esperado "${typeTranslation(currentValidatingField.type)}", 
            mas foi encontrado "${typeTranslation(typeof value)}".`
          )
        }
        if (currentValidatingField.maxLength && value.length > currentValidatingField.maxLength) {
          throw new Error(`${value.length} caracteres excede o máximo de ${currentValidatingField.maxLength} caracteres.`)
        }
        return value
      case 'boolean':
        if (typeof value === 'boolean' || (typeof value === 'string' && acceptedBooleanValues.includes(value?.toLowerCase()))) {
          return value
        }
        throw new Error(
          `Esperado "${typeTranslation(currentValidatingField.type)}", 
          mas foi encontrado "${typeTranslation(typeof value)}". Formatos aceitos: ${acceptedBooleanValues.join(',')}.`
        )
      case 'date':
        const regex = /^\d{4}-\d{2}-\d{2}$/
        if (regex.test(value)) {
          return value
        } else {
          throw new Error(
            `Esperado "${typeTranslation(currentValidatingField.type)}", 
            mas foi encontrado "${typeTranslation(typeof value)}". Formatos aceitos: AAAA-MM-DD.`
          )
        }
      case 'time':
        const regexHour = /^(?:[01]\d|2[0-3]):[0-5]\d(?::[0-5]\d)?$/
        if (regexHour.test(value)) {
          return DateTime.fromISO(value).toFormat('HH:mm')
        } else {
          throw new Error(
            `Esperado "${typeTranslation(currentValidatingField.type)}", 
            mas foi encontrado "${typeTranslation(typeof value)}". Formatos aceitos: HH:mm ou HH:mm:ss.`
          )
        }
      case 'fixed':
        if (currentValidatingField.validEntries.includes(value?.toLowerCase())) {
          return value.toLowerCase()
        } else {
          throw new Error(
            `Esperado "${typeTranslation(currentValidatingField.type)}", 
            mas foi encontrado "${typeTranslation(typeof value)}". Formatos aceitos: ${currentValidatingField.validEntries.join(', ')}.`
          )
        }
      default:
        return value
    }
  } catch (error) {
    throw new Error(error.message)
  }
}

function typeTranslation(type) {
  switch (type) {
    case 'float':
      return 'decimal'
    case 'int':
    case 'number':
      return 'número'
    case 'string':
      return 'texto'
    case 'boolean':
      return 'sim ou não'
    case 'date':
      return 'data'
    case 'time':
      return 'hora'
    case 'fixed':
      return 'valor pré definido'
    case 'object':
      return 'objeto'
    default:
      return '-'
  }
}
